.ant-carousel .slick-slide {
  text-align: center;
  height: 170px;
  background: #364d79;
  overflow: hidden;
  border-radius: 10px;
}

.ant-carousel .slick-dots-bottom {
  bottom: -4px;
}

.ant-carousel .slick-slide h3, .contentProduct h4 {
  color: #fff;
}

.divSlide {
  max-height: 170px;
  display: flex!important;
  align-items: center;
}

.divSlide img {
  max-height: 170px;
  padding: 5px;
}

.TituloProductos {
  display: flex;
  justify-content: center;
}
.TituloProductos h1 {
  background-color:#fdc618;
  width: 50%;
  text-align: center;
  border-radius: 0 10px;
}

.contentProduct {
  text-align: justify;
  padding: 5px;
  max-height: 170px;
  font-size: 0.8em;
}

.contentProduct p {
  padding-right: 10px;
  color: #ccc;
}

/* .divSlide {
  max-height: 170px;
  display: flex;
  align-items: center;
} */
/* .divSlide img{
  max-height: 165px;
} */

.ant-modal-content{
    background-color: rgba(0, 0, 0, 0);
    border-radius: 20px;
}

.ant-modal-body{
    padding: 0;
}

.ant-modal-close-x span{
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
}

.ant-modal-body img{
    border-radius: 8px;
}

/* custom */
.windowInfoContainer{
    display: flex;
    justify-content: space-around;
    text-align: center;
}
.windowInfoContainer h3{
    margin-right: 5px;
}

.btnLocation{
    z-index: 3000;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 10px 110px 0;
    cursor: pointer;
    background-color: rgb(239,239,239);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.contBtnRegistro{
    width: 60%;
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.contBtnRegistro button{
    background-color: #004a97;
    border: none;
}
.contBtnRegistro button:hover{
    background-color: rgba(24,72,135,0.9);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

/* botón de cierre de banner */
.ant-modal-close-x span {
    background-color: rgba(255, 255, 255, 0.4);
}



@media (max-width: 600px) {

    .contBtnRegistro {
        width: 76%;
    }
    .contBtnRegistro button span{
        font-size: 0.8em;
    }
}

@media (min-width: 768px) {
    .contBtnRegistro {
        width: 66%;
    }
}

@media (min-width: 1024px) {
    .contBtnRegistro {
        width: 60%;
    }
}

@media (max-width: 1024px) {
    div.map > div.searchContainer {
        width: 100vw!important;
        top: 155px!important;
        align-items: center;
    }
    div.map > div.searchContainer > .buscador {
        width: 90vw!important;
        padding-left: 0!important;
    }
}
.ant-form-item{
    justify-content: center !important;
}

.ant-input-prefix {
    margin-right: 10px;
}

.ant-checkbox-checked .ant-checkbox-inner{
    background-color: #004a97;
    border-color: #004a97;
}

.ant-btn-primary{
    background: #004a97;
}
.ant-btn-primary:hover{
    background: rgba(24,72,135,0.9);
}
.adver {
    position: relative;
    color: red;
    top: -9px;
}
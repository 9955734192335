.frmEditM > span {
    margin: 4px;
}
/* .frmEditM > .ant-select {
    width: 100%;
    margin-left: 4px;
}
#registroLicorera > div > span:nth-child(2) > span.ant-input-prefix {
    width: 97%;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    -webkit-appearance: none;
    outline: 0;
} */